import * as React from 'react'
import * as css from './MaterialPreview.module.css'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import newtab from 'utils/newtab'

const Pdf = ({ file, alt }) => (
  <div className={`${css.pdf} aspect-ratio`} style={{ '--aspect-ratio': 0.775 }}>
    <object
      data={file.asset.url}
      type="application/pdf"
    >{alt}</object>
  </div>
)

const Thumbnail = ({ file, external, thumbnail, alt }) => (
  <div className="text-center">
    <a className={css.img} href={external || file.asset.url} {...newtab}>
      <GatsbyImage
        image={getImage(thumbnail.asset)}
        alt={alt}
      />
    </a>
  </div>
)

const MaterialPreview = props => {
  const isPdf = props.file?.asset && /.(pdf)$/.test(props.file.asset.url)
  const hasThumbnail = props.file?.thumbnail

  if (isPdf)
    return <Pdf {...props} />

  else if (hasThumbnail)
    return <Thumbnail {...props} />

  return null
}

export default MaterialPreview
