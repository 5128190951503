import * as React from 'react'
import * as css from './material.module.css'
import { graphql } from 'gatsby'
import DefaultLayout from 'layouts/Default'
import { Link } from 'gatsby'
import newtab from 'utils/newtab'
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md'
import MaterialPreview from 'components/pg-library/MaterialPreview'
import clsx from 'clsx'

const MaterialPg = ({ data }) => {
  const { meta, file, seo } = data.sanityMarketingMaterial

  const products = meta.products.map(({ title }) => title).join`, `

  let sentence
  switch(file.type) {
    case 'Video':
      sentence = 'Watch the video'; break
    case 'Flyer':
      sentence = 'View the flyer'; break
    case 'Brochure':
      sentence = 'View the brochure'; break
    case 'Presentation':
      sentence = 'View the presentation'; break
    default:
      sentence = 'View'; break
  }

  return (
    <DefaultLayout {...seo}>
      <section className="container padding">
        <div className={css.breadcrumbs}>
          <Link className={css.crumb} to="/services-tools/marketing-library">Marketing Library</Link>
          {' / '}
          {products}
        </div>

        <div className={clsx(css.layout, 'grid', !['Flyer', 'Brochure'].includes(file.type) && 'split')}>
          <div>
            <div className="richtext sticky">
              <h1 className="h1">{meta.title}</h1>
              <p>{meta.description}</p>
              <p>
                <a className="link with-icon" href={file.external || file.file.asset.url} {...newtab}>
                  {sentence}
                  <MdKeyboardArrowRight />
                </a>
              </p>
            </div>
          </div>

          <MaterialPreview {...file} alt={meta.title} />
        </div>

        <hr className="md" />

        <div>
          <Link className="link with-icon" to="/services-tools/marketing-library">
            <MdKeyboardArrowLeft />
            Back
          </Link>
        </div>
      </section>
    </DefaultLayout>
  )
}

export default MaterialPg

export const query = graphql`query MaterialPg($id: String) {
  sanityMarketingMaterial(id: {eq: $id}) {
    meta {
      title
      description
      products { title }
    }
    file {
      type
      external
      file { asset { url } }
      thumbnail { asset { gatsbyImageData(placeholder: NONE, height: 400) } }
    }
    seo {
      title
      description
    }
  }
}`
